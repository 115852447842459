@import '../../styles/customMediaQueries.css';

.root {
    background-color: var(--colorWhite);
}

.content {
    width: calc(100% - 48px);
    margin: 12px 24px 24px 24px;

    @media (--viewportMedium) {
        max-width: 604px;
        margin: 55px auto 56px auto;
    }
}

.container {
    text-align: justify;
}

.title {
    text-align: center;
    margin-bottom: 60px;
}

.mainList {
    text-align: justify;
    padding: 0;
    list-style-position: inside;

    ol {
        padding-left: 20px;
    }

    li::marker {
        font-weight: bolder;
    }
}

.nestedList3>li::marker {
    content: "3." counter(list-item) " ";
}

.nestedList5>li::marker {
    content: "5." counter(list-item) " ";
}

.nestedList6>li::marker {
    content: "6." counter(list-item) " ";
}