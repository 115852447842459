.container {
    text-align: justify;
}

.title {
    text-align: center;
    margin-bottom: 60px;
}

.mainList {
    text-align: justify;
    padding: 0;
    list-style-position: inside;

    li::marker {
        font-weight: bolder;
    }
}

.mainList2 {
    text-align: justify;
    padding-left: 20px;

    ol {
        padding-left: 60px;
        list-style-position: outside;
    }

    li::marker {
        font-weight: bolder;
    }
}

.nestedList1>li::marker {
    content: "1." counter(list-item) " ";
}

.nestedList2>li::marker {
    content: "2." counter(list-item) " ";
}

.nestedList3>li::marker {
    content: "3." counter(list-item) " ";
}

.nestedList4>li::marker {
    content: "4." counter(list-item) " ";
}

.nestedList5>li::marker {
    content: "5." counter(list-item) " ";
}

.nestedList6>li::marker {
    content: "6." counter(list-item) " ";
}